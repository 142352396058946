<template>
  <div id="content">
    <div class="scroll">
      <div id="home">
        <h1 class="one">Nomadicpod’s Documentation</h1>

        <h1 class="two" style="color: #004aad">
          Use Nomadicpod API’s to facilitate bookings in minutes
        </h1>

        <p class="pb-5">
          Nomadicpod aggregates and distributes access to travel data and
          resources through a robust API suite, helping you build travel Apps faster.
        </p>
        <h1 class="three" style="color: #ba68c8">
          To get started with our APIs
        </h1>
        <ul>
          <li>Create an Account</li>
          <li>
            Get your API Key and Base URL once you have successfully logged in
          </li>
          <li>Send in Requests</li>
        </ul>
        <h1 class="four py-8" style="color: #7b68c8">
          Explore Resources
        </h1>
      </div>
<!--      <div id="FreeResources">-->
        <div class="tw-flex tw-w-full tw-flex-col">
          <h1 class="api-title">Authorization (How to get Bearer token from Authorization server)</h1>

          <p class="endpoint">Url: <span>AuthBaseUrl(From your dashboard)+"/realms/nomadicpod/protocol/openid-connect/token"</span></p>
          <p class="text-left">method: "POST"</p>
          <p class="text-left">Request type: "application/www-form-urlencoded"</p>
          <p class="text-left">Request body:
            <span class="tw-mr-2">
             client_id : authClientId
            </span>
            <span class="tw-mr-2">
             grant_type : password
            </span>
            <span class="tw-mr-2">
             username : your email address
            </span>
            <span class="tw-mr-2">
             password : your password
            </span>
          </p>
        </div>
        <div
            v-for="(api, index) in Apis"
            :key="index"
            class="Api-div"
            :id="api.divId"
        >
          <h1 class="api-title">{{ api.title }}</h1>
          <p class="endpoint">
            Endpoint:
            <span> {{ api.link }} </span>
          </p>
          <p class="endpoint">Request Type: {{api.method}}</p>
          <div class="horizontal-scroll">
            <v-simple-table class="py-6" >
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Options</th>
                  <th class="text-left">Required</th>
                  <th class="text-left">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(request,index) in api.requests" :key="index">
                  <td>{{ request.options }}</td>
                  <td>{{ request.required }}</td>
                  <td>
                    <p>{{request.dataType}}</p>
                    {{ request.desc }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-card class="mx-auto card-api-request">
              <v-card-title class="tw-flex flex-row justify-space-between">
                <p style="color: rgba(29, 38, 45, 0.71) !important;">Request(JSON)</p>
              </v-card-title>
              <v-card-text>
                <code style="color: rgba(29, 38, 45, 0.71)">{{api.requestBody}}</code>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto card-api-response">
              <v-card-title class="tw-flex flex-row justify-space-between">
                <p>Response(JSON)</p>
              </v-card-title>
              <v-card-text>
                <code style="color: white">{{api.response}}</code>

              </v-card-text>
            </v-card>

          </div>
        </div>
<!--        <div id="companies">-->

<!--        </div>-->
<!--      </div>-->
      <div id="terminals" ref="terminal">

      </div>
      <div id="PaidResources">
        <div id="companiess">

        </div>
        <div id="terminalss">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {

  },
  data() {
    return {
      Apidetails: [
        {
          apiKey: "'api_key':'Your api key'",
          companyName: "'Company Name':'Nomadicpod '",
          transportID: "'Transport company ID':'505'",
        },
      ],
      Apis: [
        { divId: "Get All Operational Cities",
          title: "Get all transport service provider operational cities",
          link: "/api/v1/developer/access-trip/get-all-operational-cities",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey (Body)",
              required: "Yes",
              desc: "Your API Key (It can be found on your dashboard)",
              dataType: "String"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY"
          },
          response:[
            {
              "city": "Ekwusigo",
              "state": "Anambra State"
            },
            {
              "city": "Umuahia",
              "state": "Abia State"
            },
            {
              "city": "Obowo",
              "state": "Imo State"
            },
            {
              "city": "Port-Harcourt",
              "state": "Rivers State"
            },
            {
              "city": "Warri",
              "state": "Delta State"
            },
            {
              "city": "Orlu",
              "state": "Imo State"
            },
            {
              "city": "Agege",
              "state": "Lagos"
            },
            {
              "city": "Onitsha",
              "state": "Anambra State"
            },
            {
              "city": "Yaba",
              "state": "Lagos"
            },
            {
              "city": "Kosofe",
              "state": "Lagos"
            },
          ],
        },
        { divId: "Get All Trips by Search Query",
          title: "Get all pending trips of transport companies subscribed to by search query",
          link: "/api/v1/developer/access-trip/get-all-pending-trips-of-transport-companies-subscribed-to-by-search-query",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey (Body)",
              required: "Yes",
              desc: "Your API Key (It can be found on your dashboard)",
              dataType: "String"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY",
            departureCity: "Yaba",
            destinationCity: "Benin city",
            departureDate: "2023-12-01"

          },
          response:[
            {
              "tripId": 3698,
              "departureTerminalName": "7 Junction",
              "departureCity": "ORHIONMWON",
              "departureState": "EDO STATE",
              "destinationTerminalName": "Kuje Main",
              "destinationCity": "KUJE",
              "destinationState": "FEDERAL CAPITAL TERRITORY",
              "takeOffTime": "06:15",
              "takeOffDate": "2023-06-02",
              "tripAmount": 3.0,
              "discountAmount": 0.0,
              "transportCompanyName": "Civico Transit",
              "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
              "tripRegion": "local",
              "tripCategories": [],
              "tripStops": 2,
              "status": "PENDING",
              "vehicle": {
                "vehicleBrand": "Toyota",
                "vehicleCapacity": 18,
                "vehicleClass": "Executive",
                "vehicleImage": "http://res.cloudinary.com/myroadpadi/image/upload/v1666184411/hnzzl15zur4ksughrsh0.png",
                "vehicleType": "BUS",
                "seats": [
                  {
                    "seatNumber": "1",
                    "status": "BOOKED",
                    "selectedBy": "Driver"
                  },
                  {
                    "seatNumber": "2",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "3",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "4",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "5",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "6",
                    "status": "AVAILABLE",
                    "selectedBy": "jahyg2gi3a"
                  },
                  {
                    "seatNumber": "7",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "8",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "9",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "10",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "11",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "12",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "13",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "14",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "15",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "16",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "17",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "18",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  }
                ]
              }
            }
          ],
        },
          // Get A Trip
        {
          divId: "Get a Trip",
          title: "Get a pending trip of transport company subscribed to",
          link: "/api/v1/developer/access-trip/get-trip-of-transport-company-subscribed-to",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey (Body)",
              required: "Yes",
              desc: "Your API Key ( It can be found on your dashboard )",
              dataType: "String"
            },
            {
              options: "tripId",
              required: "Yes",
              desc: "The Id of the trip you want to fetch",
              dataType: "Number"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY",
            tripId: 4125

          },
          response:{
            "tripId": 4125,
            "departureTerminalName": "Jibowu",
            "departureCity": "LAGOS MAINLAND",
            "departureState": "LAGOS",
            "destinationTerminalName": "Ekenwan Campus Terminal",
            "destinationCity": "Benin City",
            "destinationState": "Edo State",
            "takeOffTime": "07:00",
            "takeOffDate": "2023-07-14",
            "tripAmount": 5.0,
            "discountAmount": 0.0,
            "transportCompanyName": "Civico Transit",
            "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
            "tripRegion": "local",
            "tripCategories": [],
            "tripStops": 0,
            "status": "PENDING",
            "vehicle": {
              "vehicleBrand": "Toyota",
              "vehicleCapacity": 7,
              "vehicleClass": "Economy",
              "vehicleImage": "http://res.cloudinary.com/myroadpadi/image/upload/v1688644481/vjm17nys0egsbvpde20j.png",
              "vehicleType": "VAN",
              "seats": [
                {
                  "seatNumber": "1",
                  "status": "BOOKED",
                  "selectedBy": "Driver"
                },
                {
                  "seatNumber": "2",
                  "status": "BOOKED",
                  "selectedBy": null
                },
                {
                  "seatNumber": "3",
                  "status": "AVAILABLE",
                  "selectedBy": null
                },
                {
                  "seatNumber": "4",
                  "status": "BOOKED",
                  "selectedBy": "2u8386xcl0o"
                },
                {
                  "seatNumber": "5",
                  "status": "BOOKED",
                  "selectedBy": "kkphns15van"
                },
                {
                  "seatNumber": "6",
                  "status": "BOOKED",
                  "selectedBy": null
                },
                {
                  "seatNumber": "7",
                  "status": "AVAILABLE",
                  "selectedBy": "2it33xpguf7"
                }
              ]
            }
          }
        },
          // Book a trip
        {
          divId: "Book a Trip",
          title: "Book a trip of transport company subscribed to",
          link: "/api/v1/developer/access-booking/new-booking",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey (Body)",
              required: "Yes",
              desc: "Your API Key ( It can be found on your dashboard )",
              dataType: "String"
            },
            {
              options: "Transport Company Name",
              required: "Yes",
              desc: "The name of the Transport Company, you want to book",
              dataType: "String"
            },
            {
              options: "Booking Details",
              required: "Yes",
              desc: "This includes the trip Id Id, the seat(s) they selected and the details of the traveller(s), ",
              dataType: "Object"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY",
            transportCompanyName: "Efex Executive",
            bookingDetails:{
              "tripId": 4224,
              "selectedSeats":[
                {
                  "seatNumber" : "8",
                  "selectedBy": "davido"
                }
              ],
              "tripFare":500.0,
              "travellerDetails":[
                {
                  "bookingCode": "1039944476",
                  "firstName": "David",
                  "lastName": "Ample",
                  "email": "ample@gmail.com",
                  "phoneNumber": "2348163252159",
                  "gender" : "Male",
                  "dateOfBirth": "2023-07-01",
                  "nextOfKinFirstName": "Chike",
                  "nextOfKinLastName": "Anthony",
                  "nextOfKinEmail": "chigozie@gmail.com",
                  "nextOfKinPhoneNumber": "08187252701",
                  "address":{
                    "houseNumber": "10",
                    "streetName": "Surulere road",
                    "city": "Surulere",
                    "state": "Lagos",
                    "country": "Nigeria"
                  }

                }
              ]
            },
            bookingType: "local || crossBorder"

          },
          response:{
            "id": 4245,
            "bookingRefId": "CivIXSvnKt",
            "tripId": 4224,
            "tripFare": 500.0,
            "departure": "Jibowu",
            "departureCity": "LAGOS MAINLAND",
            "departureState": "LAGOS",
            "arrival": "Ekenwan Campus Terminal",
            "arrivalCity": "Benin City",
            "arrivalState": "Edo State",
            "status": "COMPLETED",
            "travellerDetails": [
              {
                "id": 4127,
                "firstName": "David",
                "lastName": "Ample",
                "email": "ample1@gmail.com",
                "phoneNumber": "2348163152750",
                "nextOfKinFirstName": "Chike",
                "nextOfKinLastName": "Anthony",
                "nextOfKinEmail": "chigozie@hotmail.com",
                "nextOfKinPhoneNumber": "08037214771",
                "bookingCode": "1039944476",
                "seatNumber": null,
                "gender": "Male",
                "address": {
                  "houseNumber": "10",
                  "streetName": "surulere road",
                  "city": "surulere",
                  "state": "lagos",
                  "country": "nigeria"
                },
                "dateOfBirth": "2023-07-01"
              }
            ],
            "tripDate": "2023-07-21",
            "tripTime": "07:30",
            "bookedBy": "Davido",
            "transactionReference": null,
            "transportCompanyName": "Civico Transit",
            "selectedSeats": [
              {
                "seatNumber": "8",
                "status": "BOOKED",
                "selectedBy": "davido"
              }
            ],
            "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
            "departureTerminalAddress": "33 AWOLOWO WAY, LAGOS MAINLAND, LAGOS, NIGERIA",
            "createdAt": "null"
          }
        },
          //Get all developer booking
        {
          divId: "All Bookings",
          title: "Get all Bookings",
          link: "/api/v1/developer/access-booking/get-all-bookings",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey (Body)",
              required: "Yes",
              desc: "Your API Key ( It can be found on your dashboard )",
              dataType: "String"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY",
          },
          response:[{
            "id": 4245,
            "bookingRefId": "CivIXSvnKt",
            "tripId": 4224,
            "tripFare": 500.0,
            "departure": "Jibowu",
            "departureCity": "LAGOS MAINLAND",
            "departureState": "LAGOS",
            "arrival": "Ekenwan Campus Terminal",
            "arrivalCity": "Benin City",
            "arrivalState": "Edo State",
            "status": "COMPLETED",
            "travellerDetails": [
              {
                "id": 4127,
                "firstName": "David",
                "lastName": "Ample",
                "email": "ample1@gmail.com",
                "phoneNumber": "2348163152750",
                "nextOfKinFirstName": "Chike",
                "nextOfKinLastName": "Anthony",
                "nextOfKinEmail": "chigozie@hotmail.com",
                "nextOfKinPhoneNumber": "08037214771",
                "bookingCode": "1039944476",
                "seatNumber": null,
                "gender": "Male",
                "address": {
                  "houseNumber": "10",
                  "streetName": "surulere road",
                  "city": "surulere",
                  "state": "lagos",
                  "country": "nigeria"
                },
                "dateOfBirth": "2023-07-01"
              }
            ],
            "tripDate": "2023-07-21",
            "tripTime": "07:30",
            "bookedBy": "Davido",
            "transactionReference": null,
            "transportCompanyName": "Civico Transit",
            "selectedSeats": [
              {
                "seatNumber": "8",
                "status": "BOOKED",
                "selectedBy": "davido"
              }
            ],
            "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
            "departureTerminalAddress": "33 AWOLOWO WAY, LAGOS MAINLAND, LAGOS, NIGERIA",
            "createdAt": "null"
          }]
        },
        //Get a booking by Ref id
        {
          divId: "Get Booking by Ref Id",
          title: "Get Booking by Reference Id",
          link: "/api/v1/developer/access-booking/get-booking-by-ref-id",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey",
              required: "Yes",
              desc: "Your API Key ( It can be found on your dashboard )",
              dataType: "String"
            },
            {
              options: "Booking Reference Id",
              required: "Yes",
              desc: "The Reference Id of the booking",
              dataType: "String"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY",
            bookingRefId: "CivIXSvnKt"
          },
          response:{
            "id": 4245,
            "bookingRefId": "CivIXSvnKt",
            "tripId": 4224,
            "tripFare": 500.0,
            "departure": "Jibowu",
            "departureCity": "LAGOS MAINLAND",
            "departureState": "LAGOS",
            "arrival": "Ekenwan Campus Terminal",
            "arrivalCity": "Benin City",
            "arrivalState": "Edo State",
            "status": "COMPLETED",
            "travellerDetails": [
              {
                "id": 4127,
                "firstName": "David",
                "lastName": "Ample",
                "email": "ample1@gmail.com",
                "phoneNumber": "2348163152750",
                "nextOfKinFirstName": "Chike",
                "nextOfKinLastName": "Anthony",
                "nextOfKinEmail": "chigozie@hotmail.com",
                "nextOfKinPhoneNumber": "08037214771",
                "bookingCode": "1039944476",
                "seatNumber": null,
                "gender": "Male",
                "address": {
                  "houseNumber": "10",
                  "streetName": "surulere road",
                  "city": "surulere",
                  "state": "lagos",
                  "country": "nigeria"
                },
                "dateOfBirth": "2023-07-01"
              }
            ],
            "tripDate": "2023-07-21",
            "tripTime": "07:30",
            "bookedBy": "Davido",
            "transactionReference": null,
            "transportCompanyName": "Civico Transit",
            "selectedSeats": [
              {
                "seatNumber": "8",
                "status": "BOOKED",
                "selectedBy": "davido"
              }
            ],
            "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
            "departureTerminalAddress": "33 AWOLOWO WAY, LAGOS MAINLAND, LAGOS, NIGERIA",
            "createdAt": "null"
          }
        },
        //Get all booked trips by developer
        {
          title: "Get all Booked Trips",
          link: "/api/v1/developer/access-trip/get-all-trips",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey",
              required: "Yes",
              desc: "Your API Key ( It can be found on your dashboard )",
              dataType: "String"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY"
          },
          response:[
            {
              "tripId": 4125,
              "departureTerminalName": "Jibowu",
              "departureCity": "LAGOS MAINLAND",
              "departureState": "LAGOS",
              "destinationTerminalName": "Ekenwan Campus Terminal",
              "destinationCity": "Benin City",
              "destinationState": "Edo State",
              "takeOffTime": "07:00",
              "takeOffDate": "2023-07-14",
              "tripAmount": 5.0,
              "discountAmount": 0.0,
              "transportCompanyName": "Civico Transit",
              "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
              "tripRegion": "local",
              "tripCategories": [],
              "tripStops": 0,
              "status": "PENDING",
              "vehicle": {
                "vehicleBrand": "Toyota",
                "vehicleCapacity": 7,
                "vehicleClass": "Economy",
                "vehicleImage": "http://res.cloudinary.com/myroadpadi/image/upload/v1688644481/vjm17nys0egsbvpde20j.png",
                "vehicleType": "VAN",
                "seats": [
                  {
                    "seatNumber": "1",
                    "status": "BOOKED",
                    "selectedBy": "Driver"
                  },
                  {
                    "seatNumber": "2",
                    "status": "BOOKED",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "3",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "4",
                    "status": "BOOKED",
                    "selectedBy": "2u8386xcl0o"
                  },
                  {
                    "seatNumber": "5",
                    "status": "BOOKED",
                    "selectedBy": "kkphns15van"
                  },
                  {
                    "seatNumber": "6",
                    "status": "BOOKED",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "7",
                    "status": "AVAILABLE",
                    "selectedBy": "2it33xpguf7"
                  }
                ]
              }
            },
            {
              "tripId": 4224,
              "departureTerminalName": "Jibowu",
              "departureCity": "LAGOS MAINLAND",
              "departureState": "LAGOS",
              "destinationTerminalName": "Ekenwan Campus Terminal",
              "destinationCity": "Benin City",
              "destinationState": "Edo State",
              "takeOffTime": "07:30",
              "takeOffDate": "2023-07-21",
              "tripAmount": 5.0,
              "discountAmount": 0.0,
              "transportCompanyName": "Civico Transit",
              "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
              "tripRegion": "local",
              "tripCategories": [],
              "tripStops": 0,
              "status": "PENDING",
              "vehicle": {
                "vehicleBrand": "Toyota",
                "vehicleCapacity": 18,
                "vehicleClass": "Executive",
                "vehicleImage": "http://res.cloudinary.com/myroadpadi/image/upload/v1666184411/hnzzl15zur4ksughrsh0.png",
                "vehicleType": "BUS",
                "seats": [
                  {
                    "seatNumber": "1",
                    "status": "BOOKED",
                    "selectedBy": "Driver"
                  },
                  {
                    "seatNumber": "2",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "3",
                    "status": "BOOKED",
                    "selectedBy": "davido"
                  },
                  {
                    "seatNumber": "4",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "5",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "6",
                    "status": "AVAILABLE",
                    "selectedBy": "8rr0to0lp2i"
                  },
                  {
                    "seatNumber": "7",
                    "status": "BOOKED",
                    "selectedBy": "davido"
                  },
                  {
                    "seatNumber": "8",
                    "status": "BOOKED",
                    "selectedBy": "davido"
                  },
                  {
                    "seatNumber": "9",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "10",
                    "status": "AVAILABLE",
                    "selectedBy": "j2a2visute"
                  },
                  {
                    "seatNumber": "11",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "12",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "13",
                    "status": "AVAILABLE",
                    "selectedBy": "geah1jkn2ac"
                  },
                  {
                    "seatNumber": "14",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "15",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "16",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "17",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "18",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  }
                ]
              }
            }
          ]
        },
        {
          title: "Get a Booked Trip",
          link: "/api/v1/developer/access-trip/get-a-trip",
          method: "POST",
          requests:[
            {
              options: "Authorization Header (Bearer token)",
              required: "Yes",
              desc: "Your Jwt token",
              dataType: "String"
            },
            {
              options: "apiKey",
              required: "Yes",
              desc: "Your API Key ( It can be found on your dashboard )",
              dataType: "String"
            },
            {
              options: "tripId",
              required: "Yes",
              desc: "The Id of the trip you want to fetch",
              dataType: "Number"
            },
          ],
          requestBody:{
            apiKey: "YOUR_API_KEY",
            tripId: 4125
          },
          response:
            {
              "tripId": 4125,
              "departureTerminalName": "Jibowu",
              "departureCity": "LAGOS MAINLAND",
              "departureState": "LAGOS",
              "destinationTerminalName": "Ekenwan Campus Terminal",
              "destinationCity": "Benin City",
              "destinationState": "Edo State",
              "takeOffTime": "07:00",
              "takeOffDate": "2023-07-14",
              "tripAmount": 5.0,
              "discountAmount": 0.0,
              "transportCompanyName": "Civico Transit",
              "transportCompanyLogo": "http://res.cloudinary.com/myroadpadi/image/upload/v1666179438/witg3vqnkubhbcst2yu4.png",
              "tripRegion": "local",
              "tripCategories": [],
              "tripStops": 0,
              "status": "PENDING",
              "vehicle": {
                "vehicleBrand": "Toyota",
                "vehicleCapacity": 7,
                "vehicleClass": "Economy",
                "vehicleImage": "http://res.cloudinary.com/myroadpadi/image/upload/v1688644481/vjm17nys0egsbvpde20j.png",
                "vehicleType": "VAN",
                "seats": [
                  {
                    "seatNumber": "1",
                    "status": "BOOKED",
                    "selectedBy": "Driver"
                  },
                  {
                    "seatNumber": "2",
                    "status": "BOOKED",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "3",
                    "status": "AVAILABLE",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "4",
                    "status": "BOOKED",
                    "selectedBy": "2u8386xcl0o"
                  },
                  {
                    "seatNumber": "5",
                    "status": "BOOKED",
                    "selectedBy": "kkphns15van"
                  },
                  {
                    "seatNumber": "6",
                    "status": "BOOKED",
                    "selectedBy": null
                  },
                  {
                    "seatNumber": "7",
                    "status": "AVAILABLE",
                    "selectedBy": "2it33xpguf7"
                  }
                ]
              }
            }
        }
      ],

      informations: [
        {
          options: "Authorization Header (Bearer token)",
          required: "Yes",
          desc: "Your Jwt token",
          dataType: "String"
        },
        {
          options: "apiKey",
          required: "Yes",
          desc: "Your API Key ( It can be found on your Dashboard )",
        },

      ],
      details: [
        {
          options: "api_key",
          required: "Yes",
          desc: "Your API Key (It can be found on your dashboard",
        },
        {
          options: "Company Name",
          required: "Yes",
          desc: "Your API Key (It can be found on your dashboard",
        },
        {
          options: "Transport Company ID",
          required: "Yes",
          desc: "Your API Key (It can be found on your dashboard",
        },
        {
          options: "Terminal ID",
          required: "Yes",
          desc: "Your API Key (It can be found on your dashboard",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
#content {
  width: 100%;
  height: 85vh;
  margin-left: 50px !important;
  padding: 50px 30px 20px;
  background: rgba(253, 255, 252, 0.06) !important;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-sizing: border-box;
  border-radius: 10px;

  @media (max-width: 1264px) {
    margin-left: 0 !important;
  }

  @media (max-width: 500px) {
    width: 100%;
    padding: 50px 20px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba(29, 38, 45, 0.71);
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 30px;
  }
}

.one {
  padding-bottom: 40px;
  color: #4e5b64;
}

.two {
  color: #004aad;
}

li {
  list-style: disc;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(29, 38, 45, 0.71);
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #dadada !important;

  border-radius: 6px;
}

.Api-div,
.terminal-div {
  background: #fdfffc;
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  padding: 40px 30px;
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }

  .endpoint {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 24px;
    color: rgba(29, 38, 45, 0.71);

    span {
      color: #004aad !important;
      width: auto;
    }
  }

  p {
    font-size: 14px !important;
  }
}

.api-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #4e5b64;
}

.v-data-table {
  background-color: inherit !important;
}

td,
th {
  padding: 20px 10px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 24px;
  color: rgba(29, 38, 45, 0.71);
}

.card-api-request {
  width: auto;
  padding: 20px;
  background-color: #FFFFFF !important;
  margin: 30px 0;
}

.card-api-response {
  width: auto;
  padding: 20px;
  background-color: #3F4D5D !important;
  margin: 30px 0;
}

.v-card__text {
  padding: 16px 0 0;

  p {
    color: rgb(244, 234, 221) !important;
  }
}

.v-card__title {
  border: none;
  border-bottom: 2px solid rgb(251, 251, 234);
  padding: 0 !important;

  p {
    color: rgb(247, 247, 234) !important;
    margin: 0 10px 0 !important;
  }
}

.horizontal-scroll {
  @media screen and(max-width: 768px) {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}
</style>